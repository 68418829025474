<template>
  <b-list-group style="border-radius: 0">
    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
      style="border-radius: 0"
      @click="showChildren()"
      :variant="variant"
    >
      {{ index }}. {{ name }}
      <!-- <a
        :href="
          getURL +
            slug +
            '?start_date=' +
            getStartDate +
            '&end_date=' +
            getEndDate
        "
        >a</a
      > -->
      <b-button
        variant="primary"
        class="btn btn-primary btn-sm pull-right"
        @click="downloadCSV()"
      >
        <b-spinner small type="grow" v-show="download.loading"></b-spinner>
        <span v-show="download.loading" class="ml-1">Уншиж байна</span>
        <i class="fa fa-download" v-show="!download.loading"></i>
        <span v-show="!download.loading" class="ml-1">Excel татах</span>
      </b-button>
      <i
        class="fa pull-right"
        :class="{
          ' fa-chevron-up': show_children,
          'fa-chevron-down': !show_children,
        }"
        v-show="children.length > 0"
      >
      </i>
    </b-list-group-item>
    <ReportItemNew
      :variant="variant"
      style="border-radius: 0"
      v-show="show_children"
      v-for="(_report, _index) in children"
      :key="_index"
      :dateRange="dateRange"
      :name="_report.name"
      :slug="_report.slug"
      :header="_report.config.header"
      :index="index + '.' + new String(_index + 1)"
      :children="_report.hasOwnProperty('child') ? _report.child : []"
    >
    </ReportItemNew>
  </b-list-group>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueCsvDownloader from "vue-csv-downloader";

export default {
  name: "ReportItemNew",
  components: { axios, VueCsvDownloader },
  props: {
    dateRange: {
      type: [Array, Object],
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    header: {
      type: [Array, Object],
      default: () => [],
    },
    index: {
      type: String,
      default: "",
    },
    children: {
      type: [Array, Object],
      default: () => [],
    },
    variant: {
      type: String,
      default: "",
    },
  },
  data: function() {
    return {
      download: {
        loading: false,
      },
      today: moment().format("YYYY-MM-DD"),
      show_children: false,
    };
  },
  computed: {
    getStartDate() {
      return moment(this.dateRange.startDate).format("YYYY-MM-DD");
    },
    getEndDate() {
      return moment(this.dateRange.endDate).format("YYYY-MM-DD");
    },
    getEndDateTo() {
      return moment(this.dateRange.endDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    getURL() {
      return this.$config.REPORT_NUMUR_URL;
    },
  },
  methods: {
    downloadCSV: function() {
      let _this = this;
      if (!_this.download.loading) {
        _this.download.loading = true;
        axios({
          url: _this.$config.REPORT_NUMUR_URL + _this.slug,
          params: {
            start_date: this.getStartDate,
            end_date: this.getEndDateTo,
          },
          method: "GET",

          responseType: "blob",
        })
          .then((response) => {
            _this.download.loading = false;
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));

            var fileLink = document.createElement("a");

            fileLink.href = fileURL;

            fileLink.setAttribute("download", this.name + ".xlsx");

            document.body.appendChild(fileLink);

            fileLink.click();
          })
          .catch(function(error) {
            _this.download.loading = false;
            _this.showToast(
              "Алдаа",
              "Алдаа гарсан байна. Дахин оролдоно уу",
              "danger"
            );
          });
      }
    },
    showChildren: function() {
      if (this.children.length > 0) {
        this.$data.show_children = !this.$data.show_children;
        this.variant = this.$data.show_children ? "info" : "";
      }
    },
  },
};
</script>
