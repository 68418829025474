export default {
    list: {
        columns: [
          { label: '№', key: 'index' },
          { label: 'Нэр', key: 'name' },
          { label: 'Үйлдэл', key: 'actions' }
        ],
        datas: [
          {
            name: 'Ерөнхий',
            slug: 'general',
            config: { header: {} },
            child: [
              {
                name: 'Хуримтлагдсан үндсэн хүү',
                slug: 'loan_interest_amount',
                config: {
                  header: {
                    'Огноо': 'actionDate',
                    'Үндсэн хүү': 'interestAmount'
                  }
                },
              },
              {
                name: 'Хуримтлагдсан торгуулийн хүү',
                slug: 'loan_undueloss_amount',
                config: {
                  header: {
                    'Огноо': 'actionDate',
                    'Торгуулийн хүү': 'unduelossAmount'
                  }
                },
              },
              {
                name: 'Олготын шимтгэл',
                slug: 'loan_take_fee',
                config: {
                  header: {
                    'Огноо': 'loanDate',
                    'Олготын шимтгэл': 'feeAmount'
                  }
                },
              },
              {
                name: 'Хугацаа хэтрүүлсэн шимтгэл',
                slug: 'loan_undueloss_fee',
                config: {
                  header: {
                    'Огноо': 'actionDate',
                    'Хугацаа хэтрүүлсэн шимтгэл': 'unduelossAmount'
                  }
                },
              },
              {
                name: 'Хугацаа хэтэрсэн зээлийн дүн',
                slug: 'loan_undueloss_balance',
                config: {
                  header: {
                    'Огноо': 'actionDate',
                    'Зээлийн дүн': 'loanBalance'
                  }
                },
              },
              {
                name: 'Хугацаа хэтэрсэн хүүгийн дүн',
                slug: 'loan_undueloss_interest',
                config: {
                  header: {
                    'Огноо': 'actionDate',
                    'Хүүгийн дүн': 'interestAmount'
                  }
                }
              },
              {
                name: 'Qpay гүйлгээний тоо',
                slug: 'qpay_count',
                config: {
                  header: {
                    'Огноо': 'transactionDate',
                    'Гүйлгээний тоо': 'transactionCount'
                  }
                }
              }
            ]
          },
          {
            name: 'Нийт илгээсэн мессежний тоо',
            slug: 'sent_sms_count',
            config: {
              header: {
                'Огноо': 'actionDate',
                'Мессежний тоо': 'smsCount'
              }
            },
          },
          {
            name: 'Нийт зээлийн хуваарь',
            slug: 'loan_schedule',
            config: {
              header: {
                'Овог' : 'lastname',
                'Нэр': 'firstname',
                'Утас': 'phoneNumber',
                'Регистрийн дугаар': 'registerNumber',
                'Төлбөр хийх огноо': 'schPaymentDate',
                'Хүү хоног': 'schInterestDay',
                'Хүүгийн дүн': 'schInterestAmount',
                'Төлөх дүн': 'schAmount',
                'Шимтгэл': 'schFee',
                'Нийт дүн': 'schTotalAmount',
                'Зээлийн үлдэгдэл': 'schLoanBalance',
                'Төлсөн эсэх': 'schIsPaid',
                'Зээл авсан огноо': 'accLoanDate',
                'Зээлийн данс': 'aacAccountNumber',
                'Хаасан эсэх': 'accIsClosed',
                'Зээлийн хэмжээ': 'accLoanAmount',
                'Зээлийн үлдэгдэл': 'accLoanBalance'
              }
            }
          },
          {
            name: 'Зээлийн хүүгийн тооцоо',
            slug: 'loan_interest_calc',
            config: {
              header: {
                  'Овог': 'lastname',
                  'Нэр': 'firstname',
                  'Утас': 'phoneNumber',
                  'Регистрийн дугаар': 'registerNumber',
                  'Огноо': 'schActionDate',
                  'Хүүгийн дүн': 'schInterestAmount',
                  'Хоногийн хүү': 'schDayInterest',
                  'Идэвхтэй эсэх': 'schIsActive',
                  'Алданги': 'schUnduelossAmount',
                  'Хугацаа хэтэрсэн хоног': 'schOverdueDay',
                  'Алдангийн хувь': 'schUnduelossPercent',
                  'Зээл авсан огноо': 'accLoanDate',
                  'Зээлийн данс': 'aacAccountNumber',
                  'Хаасан эсэх': 'accIsClosed',
                  'Зээлийн хэмжээ': 'accLoanAmount',
                  'Зээлийн үлдэгдэл': 'accLoanBalance'
              }
            }
          },
          {
            name: 'Олгосон зээлийн тайлан',
            slug: 'take_loan_list',
            config: {
              header: {
                  'Овог': 'lastname',
                  'Нэр': 'firstname',
                  'Утас': 'phoneNumber',
                  'Регистрийн дугаар': 'registerNumber',
                  'Зээлийн данс': 'accountNumber',
                  'Огноо': 'loanDate',
                  'Зээлийн хэмжээ': 'loanAmount',
                  'Хүү': 'interest',
                  'Хугацаа /сар/': 'term',
                  'Хаагдсан эсэх': 'isClosed',
                  'Зээлийн үлдэгдэл': 'loanBalance'
              }
            }
          },
          {
            name: 'Эргэн төлөлтийн тайлан',
            slug: 'repayment_list',
            config: {
              header: {
                  'Овог': 'lastname',
                  'Нэр': 'firstname',
                  'Утас': 'phoneNumber',
                  'Регистрийн дугаар': 'registerNumber',
                  'Дүн': 'amount',
                  'Огноо': 'transactionDate',
                  'Үндсэн зээл': 'principalAmount',
                  'Алданги': 'unduelossAmount',
                  'Хүү': 'interestAmount',
                  'Шимтгэл': 'fee',
                  'Төлсөн дүн': 'paymentAmount',
                  'Гүйлгээний утга': 'payDescription'
              }
            }
          },
          {
            name: 'Зээлийн үлдэгдэлийн тайлан',
            slug: 'loan_balance_list',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утас': 'phoneNumber',
                'Регистрийн дугаар': 'registerNumber',
                'Зээлийн mөрөл': 'typeName',
                'Зээлийн данс': 'accountNumber',
                'Зээл авсан огноо': 'loanDate',
                'Зээлийн дуусах огноо': 'expiryDate',
                'Зээл авсан дүн': 'loanAmount',
                'Хүү': 'interestAmount',
                'Нэмэгдүүлсэн хүү': 'unduelossAmount',
                'Зээлийн үлдэгдэл': 'loanBalance'
              }
            }
          },
          {
            name: 'Хаагдсан зээлийн тайлан',
            slug: 'closed_loan_list',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утас': 'phoneNumber',
                'Регистрийн дугаар': 'registerNumber',
                'Зээлийн данс': 'accountNumber',
                'Зээл авсан огноо': 'loanDate',
                'Зээл авсан дүн': 'loanAmount',
                'Хүү': 'interestAmount',
                'Нийт дүн': 'totalAmount'
              }
            }
          },
          {
            name: 'PDF татсан тайлан',
            slug: 'PDF_DOWNLOAD',
            config: {
              header: {
                'Ажилтан': 'sysUserName',
                'Татсан РД': 'registerNumber',
                'Утас': 'phoneNumber',
                'Төлөв': 'status',
                'Татсан огноо': 'createdDate',
                'Тайлбар': 'description'
              }
            }
          },
          {
            name: 'Барьцаа хөрөнгийн тайлан',
            slug: 'deposit_list',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Регистэрийн дугаар': 'registerNumber',
                'Улсын дугаар': 'countryCode',
                'Марк': 'mark',
                'Өнгө': 'color',
                'Үйлдвэрлэсэн он': 'incomeYear',
                'Арлын дугаар': 'registrationCode',
                'Зээлийн дүн': 'loanAmount',
                'Даатгалын компани': 'insuranceName',
                'Даатгал эхэлсэн огноо': 'insuranceStartDate',
                'Даатгал дуусах огноо': 'insuranceEndDate', 
                'Даатгалын үнэлгээний дүн': 'insuranceEvaluationAmount',
                'Үүсгэсэн огноо': 'createdDate'
              }
            }
          },
          {
            name: 'Санхүү мэдээллийн тайлан',
            slug: 'finance_list',
            config: {
              header: {
                'Овог': 'lastName',
                'Нэр': 'firstName',
                'Регистэрийн дугаар': 'registerNumber',
                'Үүсгэсэн огноо': 'createdDate',
                'Зээлийн дансны ID': 'loanAccountId',
                'Хэрэглэгчийн ID': 'customerId',
                'Дансны дугаар': 'accountNumber',
                'Зээлийн хэмжээ': 'loanAmount',
                'Зээлийн үлдэгдэл': 'loanBalance',
                'Сарын төлөлт': 'monthlyPayment',
                'Зээл олгосон огноо': 'loanDate',
                'Зээлийн хүү': 'interest',
                'Хугацаа': 'term',
                'Дуусах огноо': 'expiryDate',
                'Санхүү ID': 'id',
                'income_type': 'incomeTypeName',
                'branch': 'branchName',
                'sub_branch': 'subBranchName',
                'Сарын орлого': 'incomeMonthly',
                'Өр орлогын харьцаа': 'debtRatio',
                'Ипотекийн зээлтэй эсэх': 'isIpotek',
                'Үүсгэсэн огноо': 'createdDate'
              }
            }
          },
          {
            name: 'Алдаатай төлөлт /Хэрэглэгчээр/',
            slug: 'ERROR_REPAYMENT_LIST',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утасны дугаар': 'phoneNumber',
                'Гүйлгээний утга': 'description',
                'Сүүлчийн төлөлт хийсэн огноо': 'lastRepaymentDate',
                'Төлсөн мөнгөн дүн': 'amount',
                'Төлөлт хийсэн тоо': 'repaymentCount'
              }
            }
          }
        ]
      }
  }
  