export default {
    list: {
        columns: [
          { label: '№', key: 'index' },
          { label: 'Нэр', key: 'name' },
          { label: 'Үйлдэл', key: 'actions' }
        ],
        datas: [
          {
            name: 'KPI',
            slug: 'get_balance',
            config: {
            },
          }
        ]
      }
  }
  